import { isNumber } from 'lodash';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import EditRow from '../../utils/BulkImportModal/EditRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addScreen, readSheetAndReturnJsonScreen } from '../AJAXCalls';
import ReactTooltip from 'react-tooltip';
import BulkImportHeader from './BulkImportHeader';
import DSCMSImportes from '../../../util_files/DS-CMS-Import-es.xlsx';
import DSCMSImporten from '../../../util_files/DS-CMS-Import-en.xlsx';
import DSCMSImportpt from '../../../util_files/DS-CMS-Import-pt.xlsx';
const cloneDeep = require('lodash').cloneDeep;

class BulkImportSection extends Component {
  constructor(props){
    super(props);
    this.state = {
      sheetData: [],
      rowToEdit: {},
      emptyValues: {}
    }
  }

  componentDidMount = () => {
  }

  /* Defining the columns that will be displayed in the table. */
  sheetImportColumns = [
    {
      id: "edit",
      name: "",
      cell: (row) => <div><button onClick={() => {this.setState({ rowToEdit: row }, () => this.props.openModal('editRow'))}}><FontAwesomeIcon icon={["fa", "pen"]} className="text-purple-500 mr-3" /></button><button onClick={() => {this.removeRow(row.id)}}><FontAwesomeIcon icon={["fa", "trash"]} className="text-red-400" /></button></div>,
      width: '5%',
    },
    {
      id: "name",
      name: this.props.t('sections.wizard.stepOne.name-label'),
      cell: (row) => this.valueIsNull(row, "name") ? <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-red-400 text-lg m-1" /> : row.name,
      selector: row => row.name,
      sortable: true,
      type: "text",
    },
    {
      id: "pixel_size_width",
      name: this.props.t('sections.wizard.stepOne.widthPixels-label'),
      cell: (row) => !isNumber(row.pixel_size_width) ? <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-red-400 text-lg m-1" /> : row.pixel_size_width,
      selector: row => row.pixel_size_width,
      sortable: true,
      type: "number"
    },
    {
      id: "pixel_size_height",
      name: this.props.t('sections.wizard.stepOne.heightPixels-label'),
      cell: (row) => !isNumber(row.pixel_size_height) ? <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-red-400 text-lg m-1" /> : row.pixel_size_height,
      selector: row => row.pixel_size_height,
      sortable: true,
      type: "number"
    },
    {
      id: "position_x_canvas",
      name: this.props.t('sections.wizard.stepOne.canvasX-label'),
      cell: (row) => this.valueIsNull(row, "position_x_canvas") ? <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-red-400 text-lg m-1" /> : !isNumber(row.position_x_canvas) ? <span>{row.position_x_canvas} <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-yellow-400 text-lg m-1" /></span> : row.position_x_canvas,
      selector: row => row.position_x_canvas,
      sortable: true,
      type: "number"
    },
    {
      id: "position_y_canvas",
      name: this.props.t('sections.wizard.stepOne.canvasY-label'),
      cell: (row) => this.valueIsNull(row, "position_y_canvas") ? <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-red-400 text-lg m-1" /> : !isNumber(row.position_y_canvas) ? <span>{row.position_y_canvas} <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-yellow-400 text-lg m-1" /></span> : row.position_y_canvas,
      selector: row => row.position_y_canvas,
      sortable: true,
      type: "number"
    },
    {
      id: "rotation",
      name: this.props.t('sections.wizard.stepOne.rotations.label'),
      cell: (row) => this.valueIsNull(row, "rotation") ? <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-red-400 text-lg m-1" /> : !isNumber(row.rotation) ? <span>{row.rotation} <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-yellow-400 text-lg m-1" /></span> : row.rotation,
      selector: row => row.rotation,
      sortable: true,
      type: "number"
    },
    {
      id: "total_spots",
      name: this.props.t('sections.wizard.stepTwo.spotsInLoop-label'),
      cell: (row) => this.valueIsNull(row, "total_spots") ? <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-red-400 text-lg m-1" /> : !isNumber(row.total_spots) ? <span>{row.total_spots} <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-yellow-400 text-lg m-1" /></span> : row.total_spots,
      selector: row => row.total_spots,
      sortable: true,
      type: "number"
    },
    {
      id: "ad_duration",
      name: this.props.t('sections.wizard.stepTwo.spotsDuration-label'),
      cell: (row) => this.valueIsNull(row, "ad_duration") ? <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-red-400 text-lg m-1" /> : !isNumber(row.ad_duration) ? <span>{row.ad_duration} <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-yellow-400 text-lg m-1" /></span> : row.ad_duration,
      selector: row => row.ad_duration,
      sortable: true,
      type: "number"
    },
    {
      id: "auto_launch",
      name: this.props.t('sections.wizard.stepTwo.autoLaunch.title'),
      cell: (row) => <input type={'checkbox'} className="m-1" checked={row.auto_launch} onClick={(e) => {console.log(e.target)}} disabled/>,
      selector: row => row.auto_launch,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      sortable: true,
      type: "checkbox"
    },
    {
      id: "schedule.start",
      name: this.props.t('sections.wizard.stepTwo.autoLaunch.on'),
      cell: (row) => this.valueIsNull(row, "schedule.start") ? <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-red-400 text-lg m-1" /> : row.schedule[0]?.start,
      selector: row => row.schedule[0]?.start,
      sortable: true,
      type: "time"
    },
    {
      id: "schedule.end",
      name: this.props.t('sections.wizard.stepTwo.autoLaunch.off'),
      cell: (row) => this.valueIsNull(row, "schedule.end") ? <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-red-400 text-lg m-1" /> : row.schedule[0]?.end,
      selector: row => row.schedule[0]?.end,
      sortable: true,
      type: "time"
    },
    {
      id: "location.coordinates.lat",
      name: `${this.props.t('sections.wizard.stepTwo.location')} Lat.`,
      cell: (row) => this.valueIsNull(row, "location.coordinates.lat") ? <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-red-400 text-lg m-1" /> : !isNumber(row.location?.coordinates.lat) ? <span>{row.location?.coordinates.lat} <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-yellow-400 text-lg m-1" /></span> : row.location.coordinates.lat,
      selector: row => row.location?.coordinates.lat,
      sortable: true,
      type: "number"
    },
    {
      id: "location.coordinates.lng",
      name: `${this.props.t('sections.wizard.stepTwo.location')} Lng.`,
      cell: (row) => this.valueIsNull(row, "location.coordinates.lng") ? <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-red-400 text-lg m-1" /> : !isNumber(row.location?.coordinates.lng) ? <span>{row.location?.coordinates.lng} <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-yellow-400 text-lg m-1" /></span> : row.location.coordinates.lng,
      selector: row => row.location?.coordinates.lng,
      sortable: true,
      type: "number"
    },
    {
      id: "programmatic_view",
      name: this.props.t('sections.reports.sections.programmatic'),
      cell: (row) => <input type={'checkbox'} className="m-1" checked={row.programmatic_view} onClick={(e) => {console.log(row)}} disabled/>,
      selector: row => row.programmatic_view,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      sortable: true,
      type: "checkbox"
    },
    {
      id: "SSPID",
      name: "SSPID",
      cell: (row) => !this.valueIsNull(row, "SSPID") || isNumber(row.SSPID) || row.SSPID > 0 ? row.SSPID : <span>{row.SSPID} <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-yellow-400 text-lg m-1" /></span>,
      selector: row => row.SSPID,
      sortable: true,
      type: "number"
    },
  ];

  /**
   * Function that returns all the columns with type prop
   * @returns Array of objects
   */
  mandatoryFields = () => this.sheetImportColumns.filter(col => col.type);

  /**
   * A function that is called when the user clicks on the button. It calls the click() function on the
   * input element.
   */
  handleClick = () => {
    this.inputElement.click();
  }

  /**
   * If the field is a nested field, then get the value of the nested field, otherwise get the value of
   * the field.
   * @param row - The row of data that is being evaluated.
   * @param field - The field name to check for null values.
   * @returns a boolean value.
   */
  valueIsNull = (row, field) => {
    if(field.includes(".")){
      let arrayOfFields = field.split('.');
      let actualValue = row;
      arrayOfFields.forEach(subField => {
          if(!actualValue[0]){
            actualValue = actualValue[subField];
          }else{
            actualValue = actualValue[0][subField];
          }
      });
      return actualValue === null || actualValue === '';
    }
    return row[field] === null || row[field] === '';
  }

  /**
   * It takes a file, send it to backend, and returns a JSON object based on the content of the file.
   * @param e - the event object
   */
  handleImportSheet = async (e) => {
    let sheetFiles = e.target.files;
    try {
      let readSheetResponse = await readSheetAndReturnJsonScreen(sheetFiles);
      let sheetData = readSheetResponse.data;
      sheetData = sheetData.map((data, index) => {
        if(data.schedule){
          if(data.schedule[0].start.length === 4){
            data.schedule[0].start = `0${data.schedule[0].start}`
          }
          if(data.schedule[0].end.length === 4){
            data.schedule[0].end = `0${data.schedule[0].end}`
          }
        }
        return {...data, id: index}
      })
      this.getEmptyValues(sheetData);
      this.setState({
        sheetData
      })
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Function that edits especific row
   * @param editedRow - The row that was edited
   */
  handleEditRow = (editedRow) => {
    let allData = cloneDeep(this.state.sheetData);
    allData = this.updateIds(allData);
    editedRow = this.updateRowSchedule(editedRow);
    allData.splice(editedRow.id, 1, cloneDeep(editedRow));
    this.setState({
      sheetData: []
    }, () => {
      this.setState({
        sheetData: allData
      }, () => {
        this.getEmptyValues(this.state.sheetData);
      })
    })
  }

  /**
   * Function that update schedule array inside a row
   * @param {Object} row
   * @returns
   */
  updateRowSchedule = (row) => {
    let start = row.schedule[0].start, end = row.schedule[0].end;
    row.schedule.forEach(day => {
      day.start = start;
      day.end = end;
    })
    return row;
  }

  /**
   * Function that edit de ids of the rows in state, in order to not have any position problem
   * @param rows
   * @returns Array of rows
   */
  updateIds = (rows) => {
    rows.forEach((row, index) => {
      row.id = index;
    })
    return rows;
  }

  /**
   * It removes the row with the id that is passed in, then updates the ids of the remaining rows, and
   * then updates the state with the new data.
   * @param id - the id of the row to be deleted
   */
  removeRow = (id) => {
    let allData = this.state.sheetData;
    allData = allData.filter(data => data.id !== id);
    allData = this.updateIds(allData);
    this.setState({
      sheetData: allData
    }, () => {
      this.getEmptyValues(this.state.sheetData);
    })
  }

  /**
   * It takes an array of objects, and for each object, it checks if the object has a value for each of
   * the keys in the mandatoryFields array. If it doesn't, it increments the value of the key in the
   * emptyValues object
   * @param values - an array of objects
   */
  getEmptyValues = (values) => {
    let emptyValues = {};
    values.forEach(value => {
      this.mandatoryFields().forEach(key => {
        let keyValue = this.getValue(value, key.id);
        if(!emptyValues[key.id]){
          emptyValues[key.id] = 0;
        }
        if(keyValue === null || keyValue === ''){
          emptyValues[key.id]++;
        }
      })
    });
    this.setState({
      emptyValues
    })
  }

  /**
   * It takes a value and a key, and if the key contains a period, it splits the key into an array of
   * keys, then it iterates through the array of keys and returns the value of the key.
   * @param value - The object you want to get the value from
   * @param key - the key of the object you want to get the value from
   * @returns The value of the key in the object.
   */
  getValue = (value, key) => {
    if(key.includes(".")){
      let arrayOfKeys = key.split('.');
      let actualValue = value;
      arrayOfKeys.forEach(subKey => {
          if(!actualValue[0]){
            actualValue = actualValue[subKey];
          }else{
            actualValue = actualValue[0][subKey];
          }
      });
      return actualValue;
    }
    return value[key];
  }

  /**
   * If all the values in the emptyValues state are 0, return true, otherwise return false.
   * @returns a boolean value.
   */
  allValuesOK = () => {
    let allOK = true;
    let allValues = cloneDeep(this.state.emptyValues);
    let keys = Object.keys(allValues);
    keys = keys.filter((key, index) => index !== keys.length-1);
    if(keys.length === 0){
      allOK = false;
    }
    keys.forEach(key => {
      if(allValues[key] > 0){
        allOK = false;
      }
    })
    return allOK;
  }

  /**
   * Function that send screens to backend, and uploads it to DB
   */
  handleSendScreens = async () => {
    let screens = cloneDeep(this.state.sheetData);
    screens = screens.map(screen => {
      delete screen.id;
      return {
        type: "screen",
        name: screen.name,
        pixel_size_width: Number(screen.pixel_size_width),
        pixel_size_height: Number(screen.pixel_size_height),
        position_x_canvas: Number(screen.position_x_canvas),
        position_y_canvas: Number(screen.position_y_canvas),
        location: cloneDeep(screen.location),
        rotation : Number(screen.rotation),
        ad_duration: Number(screen.ad_duration),
        total_spots: Number(screen.total_spots),
        schedule: cloneDeep(screen.schedule),
        size_height_meters: '',
        size_width_meters: '',
        description: '',
        quality: '',
        screen_type: '',
        orientation: '',
        floor_distance: '',
        area_target: '',
        run_time: '',
        brand: '',
        close_areas: '',
        default_ads: '',
        min_prints: '',
        images: '',
        aspectRatio: '',
        electron_id: '',
        auto_launch: screen.auto_launch,
        programmatic_view: screen.programmatic_view,
        brightness: []
      }
    })
    try {
      const response = await addScreen(screens);
      if (response.status === 201) {
          this.props.showNotification({
              type: "success",
              text: this.props.t('common.notification.confirmationStep.screensCreated'),
              duration: 3000
          });
          setTimeout(() => {
              this.props.history.push('/screens');
          }, 3000);
      } else {
          this.props.showNotification({
              type: "error",
              text: this.props.t('common.notification.confirmationStep.createScreenError')
          });
      }
    } catch (error) {
        this.props.showNotification({
            type: "error",
            text: this.props.t('common.notification.serverError')
        });
    }
  }

  /**
   * Function that returns csv example for the given language
   * @param {String} language
   * @returns CSV File
   */
  getImportSheet = (language) => {
    switch (language) {
      case 'es':
        return DSCMSImportes;
      case 'pt':
        return DSCMSImportpt;
      default:
        return DSCMSImporten;
    }
  }

  render() {
    /* Defining the custom styles for the table. */
    const customStyles = {
      headRow: {
        style: {
          border: 'none',
        },
      },
      headCells: {
        style: {
            width: '8vw',
        },
      },
      rows: {
        highlightOnHoverStyle: {
          backgroundColor: 'rgb(140, 182, 250)',
          borderRadius: '5px',
        },
        style: {
          padding: '2px',
          border: '0'
        }
      },
      pagination: {
        style: {
          border: 'none',
        },
      },
      cells: {
          style: {
              marginLeft: '.1vw',
              marginRight: '.1vw',
          },
      },
    };
    /* Getting the language of the browser and splitting it at the hyphen. */
    const language = window.navigator.language.split("-")[0];
    return (
      <>
        <BulkImportHeader title={this.props.t('sections.wizard.bulkImportTitle')}></BulkImportHeader>
        <div className='flex justify-between'>
          <div>
            <a href={this.getImportSheet(language)} className="rounded-md buttonPrimary overflow-hidden" role='button' download>
                {this.props.t('sections.wizard.sheetImport.downloadSheet')} <FontAwesomeIcon icon={["fal", "download"]} className="" />
            </a>
            <button className="rounded-md buttonPrimary overflow-hidden" onClick={() => this.inputElement.click()}>
                {this.props.t('sections.wizard.sheetImport.uploadSheet')} <FontAwesomeIcon icon={["fal", "upload"]} className="" />
            </button>
            <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" title=" " onClick={(e) => e.target.value = ''} onChange={this.handleImportSheet} className="hidden" ref={input => this.inputElement = input}></input>
          </div>
          <div>
            <button className={`rounded-md buttonPrimary overflow-hidden ${this.allValuesOK() ? "" : "hidden"}`} onClick={() => this.handleSendScreens()}>
                {this.props.t('common.buttons.add')}
            </button>
          </div>
        </div>
        <div className='flex'>
            <div className='flex flex-col w-1/4'>
                <div className='m-1 bg-gray-200 bg-opacity-70 rounded-md p-1'>
                  <p className='font-bold text-gray-700'>{this.props.t('sections.wizard.sheetImport.fieldList')}:</p>
                  <ul className='mt-2 bg-white bg-opacity-80 divide-y divide-gray-200 divide-dotted rounded font-normal text-xs text-gray-700'>
                    {this.mandatoryFields().map((col, index) => {
                      return <>
                        <li className={`p-1 flex justify-between`}>
                          <ReactTooltip effect="solid" id={'bulkImportField'+index} >
                              <p className="text-center">
                                  {this.mandatoryFields().length-1 === index ?
                                    this.props.t('sections.wizard.sheetImport.fieldWarning').replace('_quant', this.state.emptyValues[col.id]).replace('_field', col.name)
                                  :
                                    this.props.t('sections.wizard.sheetImport.fieldError').replace('_quant', this.state.emptyValues[col.id]).replace('_field', col.name)
                                  }
                              </p>
                          </ReactTooltip>
                          {col.name}
                          <div className={`w-1/12 rounded-full flex justify-center ${!isNumber(this.state.emptyValues[col.id]) && "hidden"} ${this.state.emptyValues[col.id] > 0 ? (this.mandatoryFields().length-1 === index ? "bg-yellow-400" : "bg-red-400") : "bg-green-400"}`} data-tip data-for={'bulkImportField'+index}>
                            {this.state.emptyValues[col.id]}
                          </div>
                        </li>
                      </>
                    })}
                  </ul>
                </div>
            </div>
            <div className='custom-max-w-quarter w-3/4 m-1'>
                <DataTable
                    id="bulkImportedScreens"
                    fixedHeader
                    responsive={true}
                    columns={this.sheetImportColumns}
                    data={this.state.sheetData}
                    customStyles={customStyles}
                    highlightOnHover
                />
            </div>
        </div>
        <EditRow rowToEdit={this.state.rowToEdit} rowFieldsDataType={this.sheetImportColumns.filter(col => col.type)} modalID={'editRow'} title={this.props.t('common.buttons.edit')} handleEditRow={this.handleEditRow}></EditRow>
      </>
    )
  }
}
export default withTranslation()(BulkImportSection);
