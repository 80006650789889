
/**
     * It returns the number of times a content will be shown on the screen before the screen is
     * turned off
     * @param [content] - The content you want to know how many times it will be
     * played.
     * @returns The number of times the content will be played in the time left on the screen.
     */
 export const getTimeThatContentFitsInTimeLeftOnScreens = (content = {},state) => {
    if(state.hasOwnProperty('items') && state.hasOwnProperty('screens')){
        let screensOfContent = content.screen_id.filter(id => state.screens.some(screen => screen._id === id));
        let arrOfScreensTimes = [];
        screensOfContent.forEach(screen_id => {
            let contents = getContentsOfScreen(screen_id,state.items);
            let screen = state.screens.find(screen => screen._id === screen_id);
            let loopDuration = contents.reduce((acc,cnt) => {
                if(cnt.duration){
                    acc+=cnt.duration;
                }else{
                    acc+=Number(screen.ad_duration);
                };
                return acc;
            },0);
            let timesOfContent = contents.filter(cnt =>cnt.original_content && cnt.original_content === content._id).length+1;
            let time = getTimeLeftOfScreen(screen_id,state.screens);
            if(loopDuration === 0){
                loopDuration = 1;
            }
            let timesThatContentFitsInLeft = Math.floor((time.left/loopDuration)*timesOfContent);
            let timesThatContentFitsInTotal = Math.floor((time.total/loopDuration)*timesOfContent);
            if(loopDuration > 0 && timesThatContentFitsInLeft < content.daily_limit){
                arrOfScreensTimes[screen_id] = {
                    left: timesThatContentFitsInLeft,
                    total: timesThatContentFitsInTotal,
                };
            };
        });
        return arrOfScreensTimes;
    } else {
        return [];
    }
}
/**
 * It returns a copy of the items array, but only the items that contain the screen_id
 * @param screen_id - the id of the screen you want to get the contents of
 * @returns An array of objects that have the screen_id in their content.
 */
 export const getContentsOfScreen = (screen_id,items) => {
    return items.filter(content => content.screen_id.includes(screen_id));
};
/**
 * It takes a screen id, finds the screen in the state, finds the day of the week, finds the start
 * and end times of the screen for that day, and then returns the time left in seconds
 * @param screen_id - the id of the screen you want to get the time left of
 * @returns the time left of the screen in seconds.
 */
 export const getTimeLeftOfScreen = (screen_id, screens) =>{
    let screen = screens.find(screen => screen._id === screen_id);
    let indexOfDay = new Date().getDay() - 1;
    if (indexOfDay === -1)
    indexOfDay = 6;
    const dayToCompare = screen.schedule[indexOfDay];
    let now = new Date();
    let stringHoursMinutesNow = (now.getHours() + ':' + now.getMinutes());
    let compareParam1 = "";
    let compareParam2 = "";
    if(stringHoursMinutesNow < dayToCompare.start){
        compareParam1 = dayToCompare.start;
    }else{
        compareParam1 = stringHoursMinutesNow;
    };
    compareParam2 = dayToCompare.end;
        return {
            left: subtractHoursAndMinutesAndReturnSeconds(compareParam1,compareParam2),
            total: subtractHoursAndMinutesAndReturnSeconds(dayToCompare.start,dayToCompare.end)
        };
}
/**
 * It checks if the current time is within the time range of the screen's schedule
 * @param screen - the screen object
 * @returns A boolean value.
 */
 export const screenIsOutOfDate = (screen) => {
    let indexOfDay = new Date().getDay() - 1;
    if (indexOfDay === -1)
        indexOfDay = 6;
    const dayToCompare = screen.schedule[indexOfDay];
    const date = new Date();
    const actualDate = ("0" + date.getHours().toString()).slice(-2) + ":" + ("0" + date.getMinutes().toString()).slice(-2);
    if (dayToCompare.start > dayToCompare.end) {
        return actualDate >= dayToCompare.end && actualDate < dayToCompare.start;
    }
    return actualDate < dayToCompare.start || actualDate >= dayToCompare.end;
}
/**
 * It takes two strings in the format of "HH:MM" and returns the difference in seconds
 * @param hours1 - the first time in hours and minutes
 * @param hours2 - the time you want to subtract from
 * @returns the total number of seconds between the two times.
 */
 export const subtractHoursAndMinutesAndReturnSeconds = (hours1,hours2) => {
    let hours = hours2.split(':')[0] - hours1.split(':')[0];
    let minutes = hours2.split(':')[1] - hours1.split(":")[1];
    if(minutes< 0){
        minutes = 60 + minutes;
        hours--;
    };
    let totalSeconds = hours*3600 + minutes*60;
    if(totalSeconds<0){
      totalSeconds = -totalSeconds;
    }
    return totalSeconds || null;
};
